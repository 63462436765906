import { SessionCreateDto } from "../../dto/sessions/session-create.dto";
import { SessionDetailsBlockDto, SessionDetailsDto } from "../../dto/sessions/session-details.dto";
import { SessionDto } from "../../dto/sessions/session.dto";
import { BuildingBlock, ClientSessionStatus, Session, SessionBase, SessionDetails } from "../../models/sessions/session.model";
import { AudioFileMapper } from "../media/audio-file.mapper";
import { SessionTemplateBlockMapper } from "./session-template-block.mapper";

export class SessionMapper {
  public static toModel(dto: SessionDto): Session {
    return {
      id: dto.id,
      sessionTemplateId: dto.session_template.id,
      userId: dto.user.id,
      processingError: dto.processing_error,
      status: dto.status as ClientSessionStatus,
      processedAt: dto.processed_at? new Date(dto.processed_at) : undefined,
      createdAt: new Date(dto.created_at)
    }
  }

  public static toDetailedModel(dto: SessionDetailsDto): SessionDetails {
    return {
      ...this.toModel(dto),
      voiceId: dto.voice.id,
      backgroundAudio: dto.background_audio ? AudioFileMapper.toModel(dto.background_audio) : null,
      mainAudio: dto.main_audio ? AudioFileMapper.toModel(dto.main_audio) : null,
      binauralAudio: dto.binaural_audio? AudioFileMapper.toModel(dto.binaural_audio) : null,
      blocks: dto.blocks.map(block => this.toBuildingBlockModel(block))
    }
  }

  public static toCreateDto(sessionTemplateId: number, voiceId: number): SessionCreateDto {
    return {
      session_template_id: sessionTemplateId,
      voice_id: voiceId
    }
  }

  private static toBuildingBlockModel(dto: SessionDetailsBlockDto) : BuildingBlock {
    return {
      id: dto.id,
      script: dto.script,
      creator: 'admin',
      audioFile: dto.audio_file? AudioFileMapper.toModel(dto.audio_file) : undefined,
      sessionTemplateBlock: SessionTemplateBlockMapper.toModel(dto.session_template_block!)
    }
  }
}