import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, map, Observable } from 'rxjs';
import { environment } from '@beyou/environments/environment';
import { SmartUserProgramDto } from '../../dto/smart-programs/smart-user-program.dto';
import { SmartUserProgram, SmartUserProgramBase, SmartUserProgramDetails } from '../../models/smart-programs/smart-user-program.model';
import { SmartUserProgramMapper } from '../../mappers/smart-programs/smart-user-program.mapper';
import { SmartUserProgramDetailsDto } from '../../dto/smart-programs/smart-user-program-details';

@Injectable({
  providedIn: 'root'
})
export class SmartUserProgramApiService extends BaseApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(userId?:string, surveyId?:number): Observable<SmartUserProgram[]> {
    let apiUrl = `${environment.adminApiBaseUrl}/smart-user-programs`;
    if(userId || surveyId) {
      apiUrl += `?`;
      apiUrl = userId? `${apiUrl}&user_id=${userId}` : apiUrl;
      apiUrl = surveyId? `${apiUrl}&survey_id=${surveyId}` : apiUrl;
    }
    
    return this.getAllPagedData<SmartUserProgramDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return SmartUserProgramMapper.toModel(item);
        })
      })
    );
  }

  public async get(id:number): Promise<SmartUserProgramDetails> {
    const apiUrl = `${environment.adminApiBaseUrl}/smart-user-programs/${id}`;
    return await firstValueFrom(this.http.get<SmartUserProgramDetailsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Smart user program retrieved: ', response.id);
        return SmartUserProgramMapper.toDetailedModel(response);
      })
    ));
  }

  public async add(sp:SmartUserProgramBase): Promise<SmartUserProgram> {
    const apiUrl = `${environment.adminApiBaseUrl}/smart-user-programs`;
    const dto = SmartUserProgramMapper.toCreateDto(sp);
    return await firstValueFrom(this.http.post<SmartUserProgramDetailsDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Smart user program added: ', response.id);
        return SmartUserProgramMapper.toDetailedModel(response);
      })
    ));
  }

  public async update(sp:SmartUserProgram): Promise<SmartUserProgram> {
    const apiUrl = `${environment.adminApiBaseUrl}/smart-user-programs/${sp.id}`;
    const dto = SmartUserProgramMapper.toUpdateDto(sp);
    return await firstValueFrom(this.http.put<SmartUserProgramDetailsDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Smart user program updated: ', response.id);
        return SmartUserProgramMapper.toDetailedModel(response);
      })
    ));
  }

}
