import { SmartUserProgramCreateDto } from "../../dto/smart-programs/smart-user-program-create.dto";
import { SmartUserProgramDetailsDto } from "../../dto/smart-programs/smart-user-program-details";
import { SmartUserProgramDto } from "../../dto/smart-programs/smart-user-program.dto";
import { SmartUserProgram, SmartUserProgramBase, SmartUserProgramDetails } from "../../models/smart-programs/smart-user-program.model";
import { SmartProgramStructureMapper } from "./smart-program-structure.mapper";
import { SmartUserProgramUpdateDto } from "../../dto/smart-programs/smart-user-program-update.dto";

export class SmartUserProgramMapper {
  public static toModel(dto: SmartUserProgramDto): SmartUserProgram {
    return {
      id: dto.id,
      userId: dto.user.id,
      smartSurveyId: dto.smart_survey.id,
    }
  }

  public static toDetailedModel(dto: SmartUserProgramDetailsDto): SmartUserProgramDetails {
    const sp = SmartProgramStructureMapper.toModel(dto);
    return {
      ...sp,
      id: dto.id,
      userId: dto.user.id,
      smartSurveyId: dto.smart_survey.id,
      isDetailed: true
    }
  }

  public static toCreateDto(model: SmartUserProgramBase): SmartUserProgramCreateDto {
    const sp = SmartProgramStructureMapper.toDto(model);
    return {
      ...sp,
      smart_survey_id: model.smartSurveyId
    }
  }

  public static toUpdateDto(model: SmartUserProgram): SmartUserProgramUpdateDto {
    const sp = SmartProgramStructureMapper.toDto(model);
    return {
      ...sp
    }
  }


}