import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, map, Observable } from 'rxjs';
import { environment } from '@beyou/environments/environment';
import { SessionDto } from '../../dto/sessions/session.dto';
import { SessionDetailsDto } from '../../dto/sessions/session-details.dto';
import { Session, SessionBase, SessionDetails } from '../../models/sessions/session.model';
import { SessionMapper } from '../../mappers/sessions/session.mapper';

@Injectable({
  providedIn: 'root'
})
export class SessionApiService extends BaseApiService {

  constructor(http: HttpClient) { 
    super(http);
  }

  public getAll(): Observable<Session[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/sessions`;
    return this.getAllPagedData<SessionDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return SessionMapper.toModel(item);
        })
      })
    );
  }

  public async get(id:number): Promise<SessionDetails> {
    const apiUrl = `${environment.adminApiBaseUrl}/sessions/${id}`;
    return await firstValueFrom(this.http.get<SessionDetailsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Session details retrieved: ', response.id);
        return SessionMapper.toDetailedModel(response);
      })
    ));
  }

  public async add(sessionTemplateId: number, voiceId: number): Promise<Session> {
    const apiUrl = `${environment.adminApiBaseUrl}/sessions`;
    const dto = SessionMapper.toCreateDto(sessionTemplateId, voiceId);
    return await firstValueFrom(this.http.post<SessionDetailsDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Survey added: ', response.id);
        return SessionMapper.toDetailedModel(response);
      })
    ));
  }

  public async delete(id: number): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/sessions/${id}`;
    await firstValueFrom(this.http.delete(apiUrl));
  }
}
