import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, of, tap } from "rxjs";
import { SmartUserProgramApiService } from "../../services/api/smart-user-program-api.service";
import { SmartUserProgram, SmartUserProgramBase, SmartUserProgramDetails } from "../../models/smart-programs/smart-user-program.model";

@Injectable({
  providedIn: 'root'
})
export class SmartUserProgramService {
 
  constructor(
    private store: StoreService, 
    private smartUserProgramApiService: SmartUserProgramApiService
  ) {}

  get smartUserPrograms$(): Observable<SmartUserProgram[]> {
    return this.store.state$.pipe(map(state => Object.values(state.smartUserPrograms)));
  }

  load(): Observable<SmartUserProgram[]>{
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.smartUserProgramApiService.getAll().pipe(
      tap((smartUserPrograms) => {
        const state = { ...this.store.state };
        smartUserPrograms.forEach((smartUserProgram) => state.smartUserPrograms[smartUserProgram.id] = smartUserProgram);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading smart user programs:', error);
        throw error;
      })
    );
  }

  loadDetails(id: number): Observable<SmartUserProgramDetails> {
    const existingProgram = this.store.state.smartUserPrograms[id];
    if (existingProgram?.isDetailed) {
      return of(existingProgram as SmartUserProgramDetails);
    }

    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.smartUserProgramApiService.get(id)).pipe(
      tap((smartUserProgram) => {
        const state = { ...this.store.state };
        state.smartUserPrograms[smartUserProgram.id] = smartUserProgram;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading SmartUserProgram details:', error);
        throw error;
      })
    );
  }

  addSmartUserProgram(smartUserProgram: SmartUserProgramBase): Observable<SmartUserProgram> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.smartUserProgramApiService.add(smartUserProgram)).pipe(
      tap((smartUserProgram) => {
        const state = { ...this.store.state };
        state.smartUserPrograms[smartUserProgram.id] = smartUserProgram;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding SmartUserProgram:', error);
        throw error;
      })
    );
  }

  updateSmartUserProgram(smartUserProgram: SmartUserProgram): Observable<SmartUserProgram> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.smartUserProgramApiService.update(smartUserProgram)).pipe(
      tap((smartUserProgram) => {
        const state = { ...this.store.state };
        state.smartUserPrograms[smartUserProgram.id] = smartUserProgram;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating SmartUserProgram:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.smartUserPrograms = {};
    this.store.setState(state);
  }
}