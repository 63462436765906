import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, of, tap } from "rxjs";
import { SessionApiService } from "../../services/api/session-api.service";
import { Session, SessionBase, SessionDetails } from "../../models/sessions/session.model";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
 
  constructor(
    private store: StoreService, 
    private sessionApiService: SessionApiService
  ) {}

  get sessions$(): Observable<Session[]> {
    return this.store.state$.pipe(map(state => Object.values(state.sessions)));
  }

  load(): Observable<Session[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.sessionApiService.getAll().pipe(
      tap((sessions) => {
        const state = { ...this.store.state };
        sessions.forEach((s) => state.sessions[s.id] = s);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading building sessions:', error);
        throw error;
      })
    );
  }

  loadDetails(id: number): Observable<SessionDetails> {
    const existingSession = this.store.state.sessions[id];
    if (existingSession && 'voiceId' in existingSession) {
      return of(existingSession as SessionDetails);
    }

    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionApiService.get(id)).pipe(
      tap((session) => {
        const state = { ...this.store.state };
        state.sessions[session.id] = session;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading Session details:', error);
        throw error;
      })
    );
  }

  addSession(sessionTemplateId:number, voiceId:number): Observable<Session> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionApiService.add(sessionTemplateId,voiceId)).pipe(
      tap((newSession) => {
        const state = { ...this.store.state };
        state.sessions[newSession.id] = newSession;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding Session:', error);
        throw error;
      })
    );
  }

  deleteSession(id: number): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionApiService.delete(id)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        delete state.sessions[id];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error deleting Session:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.sessions = {};
    this.store.setState(state);
  }
}